import './App.css';
import Spotify from './components/spotify.component.jsx';
import Chat from './components/chat.component.jsx';
import Lastaction from './components/lastaction.component.jsx';
import Weather from './components/weather.component.jsx';
import { useState , useEffect } from 'react';
import weatherInt from './assets/WMOWeatherInterpretation.json';


// Creating a temporary style sheet to add an animation if the title doesn't fit in the frame
let styleSheet = document.createElement('style');
styleSheet.id = 'dynamic-styles';
document.head.appendChild(styleSheet);
let sheet = styleSheet.sheet;
sheet.insertRule(`
  @keyframes scroll-left {
    0% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(-100%);
    }
    100%{
        transform: translateX(0%);
    }
`, sheet.cssRules.length);
sheet.insertRule(`
  .defilement{
    white-space: nowrap;
    transform: translateX(0);
    animation: scroll-left 30s linear infinite;
  }
`, sheet.cssRules.length);

/**
 * Modifies the transform animation to adapt to the text size
 * @param {int} overflowValue 
 */
function modifKeyframe (overflowValue) {
  let styleSheets = document.styleSheets;
  overflowValue += 10;
  for (let i = 0; i < styleSheets.length; i++) {
    let rules = styleSheets[i].cssRules;
    for (let j = 0; j < rules.length; j++) {
      if (rules[j] instanceof CSSKeyframesRule && rules[j].name === "scroll-left") {
        if(rules[j] !== '50% { transform: translateX(' + (overflowValue) + 'px);}'){
          rules[j].deleteRule(1);
          rules[j].appendRule('50% { transform: translateX(' + (overflowValue) + 'px);}');
        }
      }
    }
  }
}

/**
 * Creates every component according to the server responses about each api.
 * @returns All the app's components (chat, stream events, weather and currently playing music)
 */
function App() {

  const [currenttimecontent, setCurrentTime] = useState(null);
  const [totaltimecontent, setTotalTime] = useState(null);
  const [imagesrc, setImage] = useState(null);
  const [artistes, setArtistes] = useState(null);
  const [album, setAlbum] = useState(null);
  const [trackname, setTrackName] = useState(null);
  const [trackProgress, setTrackProgress] = useState('100%');
  const [weatherData, setWeatherData] = useState(null);


  useEffect(() => {
    /**
     * Gets the spotifyData uri from the server that gives all the spotify data
     * about the currently playing song every second to update the spotify component's content
     */
    const getSpotifyData = () => {
        fetch('http://localhost:5000/spotifydata')
        .then(response => {
          if(!response.ok){
            throw new Error(`Erreur HTTP : ${response.status}`);
          }
          return response.json();
        })
        .catch(error => {
          console.error("Il y a eu une erreur : ", error.message);
          fetch('http://localhost:5000/spotify')
          return {};
        })
        .then(data => {
          if(!data || Object.keys(data).length === 0){
            console.warn("Aucune donnée.")
          }
          else{
            Math.floor((data.progress_ms/1000)%60) < 10 ?
            setCurrentTime(`${Math.floor((data.progress_ms / 1000) / 60)}:${Math.floor((data.progress_ms / 1000) % 60).toString().padStart(2, '0')}`) :
            setCurrentTime(`${Math.floor((data.progress_ms/1000)/60)}:${Math.floor((data.progress_ms/1000)%60).toString().padStart(2, '0')}`);
            
            Math.floor((data.item.duration_ms/1000)%60) < 10 ?
            setTotalTime(`${Math.floor((data.item.duration_ms/1000)/60)}:${Math.floor((data.item.duration_ms/1000)%60).toString().padStart(2, '0')}`) :
            setTotalTime(`${Math.floor((data.item.duration_ms/1000)/60)}:${Math.floor((data.item.duration_ms/1000)%60).toString().padStart(2, '0')}`);
            
            setImage(data.item.album.images[1].url);
    
            let artists = '';
            data.item.artists.forEach(artist => {
              artists += (artist.name + '  ')
            });
            setArtistes(artists);
            
            setAlbum(data.item.album.name);
            
            setTrackName(data.item.name);
    
            setTrackProgress(`${Math.floor((data.progress_ms*100)/data.item.duration_ms)}%`);

            let info = document.querySelectorAll(".infos");
            let boxTexte = document.querySelector("#texte");
            info.forEach(texte => {
              if(texte.scrollWidth > boxTexte.clientWidth){
                modifKeyframe(boxTexte.clientWidth - texte.scrollWidth - 25);
                if(!texte.classList.contains("defilement")){
                  texte.classList.add("defilement");
                }
              }
              else{
                texte.classList.remove("defilement");
              }

            });
          }
        }, []);
    }

    
    getSpotifyData();
    let spotifyTimer = setInterval(() => {
      getSpotifyData();
    }, 1000);
    return () => clearInterval(spotifyTimer);
  }, []);
  
  useEffect(() => {
    /**
     * Gets the wweather uri from the server that gives all the weather data
     * about the current weather in each listed city every 15 min to update the weather component's content
     */
    const getWeatherData = () => {
      fetch('http://localhost:5000/weather')
      .then(response => {
        if(!response.ok){
          throw new Error(`Erreur HTTP : ${response.status}`);
        }
        return response.json();
      })
      .catch(error => {
        console.error("Il y a eu une erreur : ", error.message);
        fetch('http://localhost:5000/weather')
        return {};
      })
      .then(data => {
        if(!data || Object.keys(data).length === 0){
          console.warn("Aucune donnée.")
        }
        else{
          console.log(data);
          let weatherData = [];
          data.forEach(element => {
            weatherData.push({
              temp : element.current.temperature_2m,
              city : element.city,
              conditions : element.current.is_day === 0 ? weatherInt[element.current.weather_code].night : weatherInt[element.current.weather_code].day
            })
          });
          setWeatherData(weatherData);
        }
      });
    }
    getWeatherData();
    let weatherTimer = setInterval(() => {
      getWeatherData();
    }, 350000);
    return () => clearInterval(weatherTimer);
  }, []);

  return (
    <div id="App">
      <Spotify
        trackProgress = {trackProgress}
        currenttimecontent = {currenttimecontent}
        totaltimecontent = {totaltimecontent}
        imagesrc = {imagesrc}
        artistes = {artistes}
        album = {album}
        trackname = {trackname}
      />
      <Weather
        weatherData = {weatherData}
      />
      <Chat/>
      <Lastaction/>
    </div>
  );
}

export default App;