import '../assets/style/weather.style.css';
import { useEffect, useState } from 'react';
const Weather = ({weatherData}) => {

    const [count, setCount] = useState(1);
    
    
    function setCities (weatherData) {
        if(!weatherData){
            return <span>No Data</span>
        }
        else{
            const res = weatherData.map((element, index) => (
                <div key={index} className={count === index ? "animateCityOut" : "animateCityIn"}>
                    <div className="cityname">{element.city}</div>
                    <div className='weatherDetails'>
                        <div className="conditions">{element.conditions.description}</div>
                        <img src={element.conditions.image} className="weatherImg" alt="rain"/>
                    </div>
                    <div className="temp">{element.temp}°C</div>
                </div>
            ));
            return res;
        }
    }
    
    let cityObj = setCities(weatherData);
    const [currentCity, setCity] = useState([cityObj[0], cityObj[1]]);

    useEffect(() => {
        let weatherDisplay = setInterval(() => {
            console.log(count)
            setCount(prevCount => {
                if (prevCount < cityObj.length - 1) {
                  setCity([cityObj[prevCount], cityObj[prevCount + 1]]);
                  return prevCount + 1;
                } else {
                  setCity([cityObj[prevCount], cityObj[0]]);
                  return 0;
                }
              });
        }, 15000);
        return () => clearInterval(weatherDisplay);
    }, [count]);
    return(
        <div id="weather">
            {currentCity}
        </div>
    )
}

export default Weather;