import '../assets/style/spotify.style.css';

/**
 * Creates a spotify component constaining all updated info about the currently playing song
 * @param {string} currenttimecontent the current time displayed for the song
 * @param {string} totaltimecontent the total time displayed for the song
 * @param {string} imagesrc the album cover
 * @param {string} artistes the artists contributing to the song
 * @param {string} album the album name
 * @param {string} trackname the track name
 * @param {string} trackProgress a percentage of the box's width to process the progress bar's length
 * @returns 
 */
const Spotify = ({currenttimecontent, totaltimecontent, imagesrc, artistes, album, trackname, trackProgress}) => {    
    const trackInfo =
            <div id="canvas">
                <img src={imagesrc} alt="" id="image" className="affichage"/>
                <div id="texte" className="affichage">
                    <div id="son" className="infos">{trackname}</div>
                    <span id="artiste" className="infos">{artistes}</span>
                    <span id="album" className="infos">{album}</span>
                </div>
                <div id="barreTemps">
                    <span id="tempsSon" className="chiffres">{currenttimecontent}</span>
                    <span id="tempsTotal" className="chiffres">{totaltimecontent}</span>
                    <div id="boxExt">
                        <div id="boxInt" style={{width: trackProgress}}></div>
                    </div>
                </div>
            </div>
    return(
        <div id="spotify">
            {trackInfo}
        </div>
    )
}


export default Spotify;